<template>
  <div v-if="item" :key="item.experience.id">
    <div class="card" :class="{ activeSpace: item.space.slug == $store.state.user.space }">
      <header class="card-header" v-if="item.space.slug != 'menu'">
        <div
          class="experience-button"
          :class="{ 'is-low': item.space.hierarchy == 'low'}"
          :style="{ '--section-color': item.space.section.color }"
          @click="GoToSpace()">
          <div class="circle">
            <d-icon v-if="item.experience.category.icon"
            :icon="item.experience.category.icon"
            size="is-small"/>
          </div>
          <div class="experience-title py-2 px-3">{{ item.experience.title }}</div>
          <div class="space p-2 has-text-weight-bold" v-if="item.space.alphanumeric">{{ item.space.alphanumeric }}</div>
        </div>

      </header>

      <div class="card-content p-0">
        <div class="content">
          <template>
            <div
              style="flex:1"
              class="card-header is-flex is-align-items-center p-4"
              :class="{ 'has-background-white-ter has-text-weight-bold': item.space.slug == 'menu'}">
              <span v-if="HasScreen()">
                <span @click="ShowCloseScreens()">
                  <d-icon
                  class="mr-2"
                  icon="FaDisplay"
                  size="is-small"
                  :type="ScreensConnectedIconType"
                  />
                </span>
              </span>
              <d-icon
                  v-else
                  class="mr-2 has-text-grey-light"
                  icon="FaMobileScreenButton"
                  size="is-small"/>
              <span class="space-title">{{ item.space.title }}</span>
              <span class="card-header-icon p-0" aria-label="More options">
                <span @click="ShowChangeExperienceDialog()" v-if="item.space.slug != 'menu'">
                  <d-icon icon="FaArrowRightArrowLeft" size="is-small" class="mx-2"/>
                </span>
                <b-dropdown aria-role="list" v-if="item.space.slug != 'menu'" position="is-bottom-left">
                    <template #trigger>
                          <d-icon icon="FaEllipsisVertical"/>
                      </template>
                    <b-dropdown-item aria-role="listitem" v-if="HasScreen()" @click="ShowVolumeDialog()">
                      <d-icon v-if="item.space.volume == 0 || item.space.volume == null" icon="FaVolumeXmark"
                        size="is-small" class="mx-2 has-text-grey"/>
                      <d-icon v-else-if="item.space.volume < 70" icon="FaVolumeLow" size="is-small" class="mx-2"/>
                      <d-icon v-else icon="FaVolumeHigh" size="is-small" class="mx-2"/> {{ $t('remote.changeVolume') }}
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" v-if="HasScreen()" @click="ShowRestartExperienceDialog()">
                      <d-icon icon="FaRotateLeft" size="is-small" class="mx-2"/> {{ $t('remote.restartExperience') }}
                    </b-dropdown-item>
                    <a v-if="!HasScreen()" :href="`/qr/${item.space.slug}`" target="_blank">
                      <b-dropdown-item aria-role="listitem">
                        <d-icon icon="FaQrcode" size="is-small" class="mx-2"/> {{ $t('remote.downloadQR') }}
                      </b-dropdown-item>
                    </a>
                </b-dropdown>

            </span>
            </div>
          </template>

          <!-- User list -->
          <template v-if="playersInSpace.length == 0">
            <p class="card-header-title">
              <d-icon icon="FaUsers" size="is-small" class="mr-2"/>
              {{ $tc('remote.usersNumber',playersInSpace.length) }}
            </p>
          </template>
          <template v-else>
            <b-collapse class="card userlist" animation="slide" aria-id="contentIdForA11y3" :open="false">
              <template #trigger="props">
                <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.closed">
                  <p class="card-header-title">
                    <d-icon icon="FaUsers" size="is-small" class="mr-2"/>
                    {{ $tc('remote.usersNumber',playersInSpace.length) }}
                  </p>
                  <a class="card-header-icon is-text-black-ter">
                    <d-icon :icon="props.open ? 'FaChevronUp' : 'FaChevronDown'" size="is-small">
                    </d-icon>
                  </a>
                </div>
              </template>

              <div class="card-content pt-0 px-4 pb-3">
                <div v-for="player in playersInSpace" :key="player.dbid" class="playeritem" :class="{me: player.dbid == $store.state.user.profile.id}">

                  <player-avatar
                    :player="player"
                    :scale="1.5"
                    :showUsername="true"
                    :horizontal="true"/>

                    <div class="p-2" v-if="player.dbid == $store.state.user.profile.id">
                      
                    </div>

                    <div class="p-2" v-else-if="player.role == 'tour-leader'">
                      <d-icon icon="FaCog" size="is-small"/>
                    </div>

                  <b-button
                    v-else
                    size="is-small"
                    type="is-inverted"
                    @click="KickPlayer(player.dbid)">
                    <d-icon icon="FaUserXmark" size="is-small"/>
                  </b-button>
                </div>
              </div>
            </b-collapse>
          </template>

        </div>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item is-size-7"
          @click="InvitePlayersToSpace(false)">{{ $t('remote.inviteAll') }}</a>
        <a href="#" class="card-footer-item is-size-7 "
          @click="InvitePlayersToSpace(true)">{{ $t('remote.pushAll') }}</a>
        <a v-if="item.experience.talkingPoints" href="#"
          class="card-footer-item is-size-7"
          @click="$store.commit('TalkingPointsShow', item.experience)">{{ $t('remote.talkingPoints') }}</a>
      </footer>
    </div>

    <!-- Restart Experience dialog -->
    <b-modal v-model="showDialogRestartExperience" scroll="keep" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{ item.experience.title }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          {{ $t('notifications.restartExperience',[this.item.experience.title,this.item.space.title]) }}
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="$t('system.close')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="HideRestartExperienceDialog()" />
          <b-button
            :label="$t('system.save')"
            type="is-primary"
            size="is-small"
            @click="RestartExperience()"
            expanded/>
        </footer>
      </div>
    </b-modal>

    <!-- Change volume dialog -->
    <b-modal v-model="showDialogChangeVolume" scroll="keep" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{ item.experience.title }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <div class="has-text-weight-bold">{{ $t('remote.changeVolume') }}</div>
          <div class="is-flex">
          <div @click="ToggleMute()">
            <!-- Icono volumen -->
            <d-icon v-if="newVolumeValue == 0 || newVolumeValue == null" icon="FaVolumeXmark" size="is-small" class="my-3 mr-1 has-text-grey"/>
            <d-icon v-else-if="newVolumeValue < 70" icon="FaVolumeLow" size="is-small" class="my-3 mr-1"/>
            <d-icon v-else icon="FaVolumeHigh" size="is-small" class="my-3 mr-1"/>
          </div>
          <b-field class="mx-2" style="width:100%">
            <b-slider v-model="newVolumeValue" lazy/>
          </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="$t('system.close')"
            size="is-small"
            type="is-primary"
            outlined
            expanded
            @click="HideVolumeDialog()" />
          <b-button
            :label="$t('system.save')"
            size="is-small"
            type="is-primary"
            expanded
            @click="SaveNewVolume()" />
        </footer>
      </div>
    </b-modal>

    <!-- Change experience dialog -->
    <b-modal v-model="showDialogChangeExperience" scroll="keep" :can-cancel="false " class="change-experience-modal">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{ item.space.title }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <b-field :label="$t('remote.changeExperience')">
            <p class="control">
              <span class="button is-static">
                <d-icon icon="FaMagnifyingGlass" size="is-small"/>
              </span>
            </p>
            <b-autocomplete
              v-model="experienceChangeName"
              :placeholder="item.experience.title"
              :open-on-focus="true"
              :data="ListaExperienciasAReemplazar"
              group-field="type"
              group-options="items"
              type="search"
              field="title"
              @select="option => (selectedExperience = option)"/>
          </b-field>

          <!-- {{playlist.find(x=>x.space.id == item.space.id))}} -->
          
          <b-button
            v-if="item.experienceOverride"
            label="Restore experience"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="RestoreExperience()" />

        </section>
        <footer class="modal-card-foot is-justify-content-end">
          
          <b-button
            :label="$t('system.close')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="HideChangeExperienceDialog()" />
          <b-button
            :label="$t('remote.change')"
            type="is-primary"
            size="is-small"
            :disabled="!selectedExperience"
            expanded
            @click="OverrideSpaceExperience(selectedExperience)" />
        </footer>
      </div>
    </b-modal>

    <!-- Close screen dialog  -->
    <b-modal v-model="showDialogCloseScreens" scroll="keep" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{ item.space.title }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          {{ $tc('remote.onlineScreens',dialogScreensConnected || 0) }}
        </section>
        <footer class="modal-card-foot is-justify-content-end">
          <b-button
            :label="$t('system.cancel')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="HideCloseScreens()"/>
            <b-button
            :label="$t('remote.closeAllScreens')"
            :disabled="!(dialogScreensConnected >= 1)"
            type="is-primary"
            size="is-small"
            expanded
            @click="CloseConnectedScreens()"/>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

import PlayerAvatar from "./PlayerAvatar.vue";

export default {
  components: { PlayerAvatar },
  props: {
    roomData: Object
  },
  data() {
    return {
      experienceChangeName: '',
      item: null,
      selectedExperience: null,
      selectedPlaylist: this.$store.state.setup.playlist,
      showDialogChangeVolume: false,
      newVolumeValue: 100,
      showDialogChangeExperience: false,
      // showDialogTalkingPoints: false,
      showDialogCloseScreens: false,
      dialogScreensConnected: 0,
      showDialogRestartExperience: false,
    };
  },
  methods: {
    CloseConnectedScreens(){
      this.$socket.client.emit("CloseConnectedScreens", { spaceId: this.item.space.id })
      this.dialogScreensConnected = 0
    },
    HasScreen() {
      if (this.item.space.slug == 'menu') return false

      const component = this.item.experience.component[0].blockType
      const componensWithouScreen = ["survey","infoSpot","puzzle"]
      if (componensWithouScreen.includes(component)) return false
      
      return true

    },
    GoToSpace() {
      this.$router.push(this.item.space.slug);
      this.$store.commit('admin/showRemote', false)
    },
    KickPlayer(dbid) {
      console.log("Kicking player", dbid)
      this.$socket.client.emit("kickPlayer", { dbid });
    },
    ShowRestartExperienceDialog() {
      this.showDialogRestartExperience = true;
    },
    HideRestartExperienceDialog(){
      this.showDialogRestartExperience = false;
    },
    RestartExperience() {
      this.$socket.client.emit("forceRestartSpace", { spaceId: this.item.space.id })
      this.showDialogRestartExperience = false;
    },
    ToggleMute(){
      if(this.newVolumeValue > 0){
        this.newVolumeValue = 0;
      }else{
        this.newVolumeValue = this.item.space.volume;
      }
    },  
    async SaveNewVolume() {
      this.$store.commit("StartLoading")
      const res = await axios.put(process.env.VUE_APP_API_BASE + "/api/spaces/" + this.item.space.id, {
        volume: this.newVolumeValue
      },
        {
          headers: {
            Authorization: "JWT " + this.$store.state.user.token,
          },
        });
      this.$store.commit("StopLoading");
      this.showDialogChangeVolume = false;
      if (res.status == 200) {
        this.item.space.volume = this.newVolumeValue
        this.$buefy.notification.open({
            type: "is-primary",
            message: this.$t('notifications.volumeChanged')
          });
        this.$sfxPlay('positivenotification')
      } else {
        this.newVolumeValue = this.item.space.volume;

        this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorChangingVolume')
        });

        this.$sfxPlay('negativenotification')
      }
    },
    ShowVolumeDialog() {
      this.showDialogChangeVolume = true;
    },
    HideVolumeDialog() {
      this.newVolumeValue = this.item.space.volume;
      this.showDialogChangeVolume = false;
    },
    InvitePlayersToSpace(force = false) {
      this.$socket.client.emit("InvitePlayersToSpace", { spaceId: this.item.space.id, force });
    },
    ShowChangeExperienceDialog() {
      this.spaceEditing = this.item.space;
      this.showDialogChangeExperience = true;
      this.experienceChangeName = '';
    },
    HideChangeExperienceDialog() {
      this.spaceEditing = null;
      this.showDialogChangeExperience = false;
    },
    async RestoreExperience(){
      this.$store.commit("StartLoading")
      console.log("***",this.selectedPlaylist.id, this.item.space.id)
      const resetUrl = process.env.VUE_APP_API_BASE + `/api/playlists/${this.selectedPlaylist.id}/reset/${this.item.space.id}`;
      console.log(resetUrl)
      const res = await axios.put(resetUrl, {},
        {
          headers: {
            Authorization: "JWT " + this.$store.state.user.token,
          },
        });
      console.log(res)
      this.$store.commit("StopLoading");

      if (res.status == 200) {
        this.$buefy.notification.open({
            type: "is-primary",
            message: this.$t('notifications.playlistReseted') // TODO cambiar texto
        });
        this.$sfxPlay('positivenotification')
      } else {
        this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorResetingExperience') // TODO cambiar texto
        });
        this.$sfxPlay('negativenotification')
      }
      this.$forceUpdate();
    },
    async OverrideSpaceExperience(newExperience) {
      
      if(newExperience.id == this.item.experience.id) {
        this.HideChangeExperienceDialog()
        return false
      } 

      // let originalPlaylist = this.$store.state.setup.playlist.content.map(x => {
      //   return {
      //     experience: x.experience?.id,
      //     experienceOverride: x.experienceOverride ? x.experienceOverride.id : null,
      //     shortcutExperiences: x.shortcutExperiences,
      //     space: x.space.id,
      //     hideInMenu: x.hideInMenu,
      //   }
      // })
      // console.log("before change", originalPlaylist.find(x => x.space == this.spaceEditing.id))
      // originalPlaylist.find(x => x.space == this.spaceEditing.id).experienceOverride = newExperience.id
      // console.log("after change", originalPlaylist.find(x => x.space == this.spaceEditing.id))

      this.$store.commit("StartLoading")
      const res = await axios.put(process.env.VUE_APP_API_BASE + `/api/playlists/${this.selectedPlaylist.id}/experienceOverride`, {
        spaceId: this.spaceEditing.id,
        experienceOverride: newExperience.id,
      },
        {
          headers: {
            Authorization: "JWT " + this.$store.state.user.token,
          },
        });
      console.log(res)
      this.$store.commit("StopLoading");

      if (res.status == 200) {

        this.$buefy.notification.open({
            type: "is-primary",
            message: this.$t('notifications.experienceChanged')
        })

        this.$sfxPlay('positivenotification')
      } else {

        this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorChangingExperience')
        });

        this.$sfxPlay('negativenotification')

      }
      Vue.nextTick(() => {
        this.HideChangeExperienceDialog()
        this.$forceUpdate();
      });


    },
    ShowCloseScreens(){
      console.log('closscreens')
      this.dialogScreensConnected = this.item.screensConnected
      this.showDialogCloseScreens = true
    },
    HideCloseScreens(){
      this.showDialogCloseScreens = false
    }
  },
  computed: {
    ListaExperienciasAReemplazar() {
      // Devuelve una lista de experiencias que se pueden reemplazar
      // Ordena y agrupa las experiencias por tipo: Shortcuts y Others
      if(this.shortcutExperiences.length > 0){
        return [
            {
                type: 'Shortcuts',
                items: this.shortcutExperiences.filter(option => {
                  // filtrado por la busqueda del campo
                  return (option.title.toLowerCase().indexOf(this.experienceChangeName.toLowerCase()) >= 0 )
                })
            },
            {
                type: 'Other',
                items: this.otherExperiences.filter(option => {
                  // filtrado por la busqueda del campo
                  return (option.title.toLowerCase().indexOf(this.experienceChangeName.toLowerCase()) >= 0 )
                })
            }
        ]
      }
      return [
        {
          type: 'All experiences',
          items: this.$store.state.admin.experiences.filter(x => 
            x.component[0]?.blockType != "infoSpot" && x.component[0]?.blockType != "survey"
          ).filter(option => {
            // filtrado por la busqueda del campo
            return (option.title.toLowerCase().indexOf(this.experienceChangeName.toLowerCase()) >= 0 )
          })
        },
      ]
    },
    ScreensConnectedIconType(){
      if(this.item.screensConnected == 0) return 'is-danger';
      if(this.item.screensConnected == 1) return 'is-success';
      return 'is-warning'
    },
    playersInSpace() {
      return this.$store.state.admin.players
      .filter((x) => x.space == this.item.space.slug)
      // eslint-disable-next-line
      .sort((a, b) => (a.dbid === this.$store.state.user.profile.id ? -1 : 0))
    },
    shortcutExperiences() {
      return this.$store.state.admin.experiences
        .filter(x => this.item.shortcutExperiences?.includes(x.id))
    },
    otherExperiences() {
      return this.$store.state.admin.experiences
        .filter(x => !this.item.shortcutExperiences.includes(x.id))
        .filter(x => x.component[0].blockType != "infoSpot" && x.component[0].blockType != "survey")
    },
    playlist() {
      return this.$store.getters.remoteItems;
    },
    ShowMe() {
      return this.$store.state.admin.showRemote;
    },
  },
  mounted() {
    this.item = this.roomData; // mutable data from prop
    this.newVolumeValue = this.item.space.volume;
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.change-experience-modal {

  .modal-card {
    overflow: visible;
  }

  .animation-content {
    overflow: visible;
  }
  .modal-card-body {
      overflow: visible;
    }
}

.autocomplete {
  width: 100%;
}

.space-title {
  flex: 1;
}
.activeSpace {
  border: 1px solid $primary;
  border-radius: 5px;
}

.playeritem {

  &.me {
    font-weight: bold;
  }

  &:nth-of-type(2n) {
    background: #FAFAFA;
  }
  button {
    border: none;
  }
}

.screensConnected{
  position: absolute;
  font-weight: normal;
  font-size: 8px;
  left: 21px;
  top: 17px;
}

a.dropdown-item {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
}
</style>
<style lang="scss">
  .playerAvatar {
    margin-right: .3em;
  }
  </style>